import styled from "@emotion/styled";
import * as React from "react";
import { Container } from "../components/container";
import { Image } from "../components/image";
import { Page } from "../components/page";
import { accentColor, breakpointMobile } from "../constants";

const StammtischContainer = styled.div`
  padding: 15rem 0;
  position: relative;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  width: 40rem;

  @media screen and (max-width: ${breakpointMobile}) {
    display: none;
  }
`;

const Title = styled.h1`
  color: ${accentColor};
  font-size: 2rem;
  font-weight: normal;
  margin: 0;
`;

const Subtitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
`;

const Content = styled.div`
  font-size: 1rem;
  margin-top: 2rem;
  width: 30rem;

  @media screen and (max-width: ${breakpointMobile}) {
    width: 100%;
  }
`;

export default () => (
  <Page>
    <ImageContainer>
      <Image label="Förderverein Logo" src="images/Logo_Team.png" />
    </ImageContainer>

    <Container>
      <StammtischContainer>
        <Title>Förderverein</Title>
        <Subtitle>Kontaktpersonen</Subtitle>

        <Content>
          Unsere Zuständigen für Webiste sind: Max Mustermann
          Unsere Zuständigen für Finanzen sind: Goldie Goldesel
        </Content>
      </StammtischContainer>
    </Container>
  </Page>
);
